<template>
	<div id="dashboard-analytics">
		<div class="vx-row">

			<!-- Welcome Card -->
			<div class="vx-col w-full mb-base">
				<vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
					<feather-icon icon="AwardIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
					<h1 class="mb-6 text-white">Welcome to Watchtower</h1>
					<p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">This dashboard provides an overview of the suspicious domains registered in the past 30 minutes.<br>Click on the stats to filter data.</p>
				</vx-card>
			</div>

			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/3 mb-base">
				<vx-card slot="no-body" class="vx-card--clickable" :class="{'border-danger': suspicionFilter.includes('high')}" @click.native="toggleSuspicionFilter('high')">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-danger" style="background: rgba(var(--vs-danger),0.15);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info "><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ suspicionStats['high'].toLocaleString() }}</h2>
							<span>High Suspicion</span>
						</div>
					</div>
				</vx-card>
			</div>

			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/3 mb-base">
				<vx-card slot="no-body" class="vx-card--clickable" :class="{'border-warning': suspicionFilter.includes('medium')}" @click.native="toggleSuspicionFilter('medium')">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-warning" style="background: rgba(var(--vs-warning),0.15);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info "><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ suspicionStats['medium'].toLocaleString() }}</h2>
							<span>Medium Suspicion</span>
						</div>
					</div>
				</vx-card>
			</div>

			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/3 mb-base">
				<vx-card slot="no-body" class="vx-card--clickable" :class="{'border-info': suspicionFilter.includes('low')}" @click.native="toggleSuspicionFilter('low')">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-info" style="background: rgba(var(--vs-info),0.15);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info "><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ suspicionStats['low'].toLocaleString() }}</h2>
							<span>Low Suspicion</span>
						</div>
					</div>
				</vx-card>
			</div>
			
			<div v-if="currentTimestamp" class="vx-col w-full mb-base">
				<vs-alert active="true" icon="new_releases">The below data is for the time period of {{ formattedTimestamp }}. <a href="javascript:void(0);" @click="removeTimestamp"><u>Click here</u></a> for latest data.</vs-alert>
			</div>

			<div class="vx-col w-full">
				<vx-card :title="`Suspicious Domains - ${$options.filters.toTitleCase(suspicionFilter.join(', '))}`">
					<!-- TABLE ACTION ROW -->
					<div class="flex flex-wrap justify-between items-center">

						<!-- ITEMS PER PAGE -->
						<div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
							<vs-dropdown vs-trigger-click class="cursor-pointer">
								<div class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium vs-dropdown-radius">
									<span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ filteredTableData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : filteredTableData.length }} of {{ filteredTableData.length }}</span>
									<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
								</div>
								<!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
								<vs-dropdown-menu>
									<vs-dropdown-item @click="gridApi.paginationSetPageSize(500)">
										<span>500</span>
									</vs-dropdown-item>
									<vs-dropdown-item @click="gridApi.paginationSetPageSize(1000)">
										<span>1000</span>
									</vs-dropdown-item>
									<vs-dropdown-item @click="gridApi.paginationSetPageSize(1500)">
										<span>1500</span>
									</vs-dropdown-item>
									<vs-dropdown-item @click="gridApi.paginationSetPageSize(1500)">
										<span>2000</span>
									</vs-dropdown-item>
								</vs-dropdown-menu>
							</vs-dropdown>
						</div>

						<!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
						<div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
							<vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
							<vs-button color="dark" type="border" icon="icon-download" icon-pack="feather" class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"></vs-button>
						</div>
					</div>
					<ag-grid-vue
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 my-4 ag-grid-table"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="filteredTableData"
					:enableRangeSelection="true"
					:suppressCopyRowsToClipboard="true"
					rowSelection="multiple"
					colResizeDefault="shift"
					:suppressDragLeaveHidesColumns="true"
					:animateRows="false"
					:floatingFilter="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:rowClassRules="rowClassRules"
					:statusBar="statusBar"
					:rowHeight="38"
					@column-resized="onColumnResized">
				</ag-grid-vue>
				<vs-pagination
				:total="totalPages"
				:max="maxPageNumbers"
				v-model="currentPage" />
				<!-- <VuePerfectScrollbar ref="agGridTablePs" class="scroll-area" :settings="psSettings" /> -->
			</vx-card>
		</div>

	</div>
</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				suspicionFilter: ['high'],
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
				},
				rowClassRules: null,
				statusBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellClass: "cell-wrap-text",
					cellRenderer: function(params) {
						return `<a href="https://anonymto.com/?https://www.whois.com/whois/${params.value}" target="_blank">${params.value}</a>`
					}
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: true,
					minWidth: 175,
					width: 100,
				},
				{
					headerName: 'Registrar',
					field: 'registrar_organization',
					filter: "agTextColumnFilter",
					minWidth: 375,
				},
				{
					headerName: 'Transaction Date',
					field: 'transaction_date',
					filter: "agTextColumnFilter",
					minWidth: 200,
					cellClass: "cell-wrap-text",
				},
				{
					headerName: 'Suspicion Level',
					field: 'suspicion_level',
					filter: false,
					minWidth: 175,
				},
				],
			}
		},
		watch: {
			suspicionFilter: function(val) {
				if(val.length == 0) {
					this.suspicionFilter.push('high');
				}
			},
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			}
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			currentTimestamp: function(){
				return this.$store.getters.getCurrentTimestamp;
			},
			formattedTimestamp: function(){
				return this.currentTimestamp != null ? new Date(this.currentTimestamp*1000) : this.currentTimestamp;
			},
			tableData: function(){
				return this.$store.getters.getStoreSpamReport;
			},
			suspicionStats: function() {
				return {
					high: this.tableData.filter(x => x.suspicion_level == "high").length,
					medium: this.tableData.filter(x => x.suspicion_level == "medium").length,
					low: this.tableData.filter(x => x.suspicion_level == "low").length,
				}
			},
			filteredTableData: function(){
				if (this.suspicionFilter.includes('high') && this.suspicionFilter.includes('medium') && this.suspicionFilter.includes('low')) {
					return this.tableData.filter(x => x.suspicion_level == "high" || x.suspicion_level == "medium" || x.suspicion_level == "low");
				}
				else if (this.suspicionFilter.includes('high') && this.suspicionFilter.includes('medium')) {
					return this.tableData.filter(x => x.suspicion_level == "high" || x.suspicion_level == "medium");
				}
				else if (this.suspicionFilter.includes('high') && this.suspicionFilter.includes('low')) {
					return this.tableData.filter(x => x.suspicion_level == "high" || x.suspicion_level == "low");
				}
				else if (this.suspicionFilter.includes('medium') && this.suspicionFilter.includes('low')) {
					return this.tableData.filter(x => x.suspicion_level == "medium" || x.suspicion_level == "low");
				}
				else if (this.suspicionFilter.includes('high')) {
					return this.tableData.filter(x => x.suspicion_level == "high");
				}
				else if (this.suspicionFilter.includes('medium')) {
					return this.tableData.filter(x => x.suspicion_level == "medium");
				}
				else if (this.suspicionFilter.includes('low')) {
					return this.tableData.filter(x => x.suspicion_level == "low");
				}
				else {
					return []
				}
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			}
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			highlightSpam() {
				this.rowClassRules = {
					'ag-row-info': 'data.suspicion_level == "low"',
					'ag-row-warning': 'data.suspicion_level == "medium"',
					'ag-row-danger': 'data.suspicion_level == "high"'
				}
			},
			toggleSuspicionFilter(val) {
				let index = this.suspicionFilter.indexOf(val);
				let self = this;

				if (index > -1) {
					self.suspicionFilter.splice(index, 1);
				}
				else {
					self.suspicionFilter.push(val);
				}
			},
			removeTimestamp() {
				this.$store.dispatch('removeCurrentTimestamp');
				// Get Spam Report Data
				this.$store.dispatch("getStoreSpamReport");
				// Remove Timestamp from URL
				this.$router.push('/');
			},
		},
		beforeMount() {
			// Highlight spam columns
			this.highlightSpam();

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;
			// const el = document.querySelector('.ag-body-viewport');
			// console.log(this.$refs.agGridTablePs)
			// this.$refs.agGridTablePs.__init(el)
			// this.$refs.agGridTablePs.update()

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);
		}
	}
</script>

<style lang="scss">
#dashboard-analytics {
	.greet-user{
		position: relative;
		.decore-left{
			position: absolute;
			left:0;
			top: 0;
		}
		.decore-right{
			position: absolute;
			right:0;
			top: 0;
		}
	}

	@media(max-width: 576px) {
		.decore-left, .decore-right{
			width: 140px;
		}
	}
}
</style>